<template>
  <div>
    <div class="d-md-flex w-100">
      <div class="indicators w-100">
        <div class="d-md-flex align-items-center justify-content-end mb-3">
          <b-button
            @click="handleAddPremise()"
            class="btn-add"
            :disabled="inRegister"
          >
            Adicionar premissa
          </b-button>
        </div>
        <div class="w-100 min-h-62">
          <div
            v-if="emptypremises && !inRegister"
            class="mx-auto d-block text-center py-5 my-5"
          >
            <img
              src="@/assets/images/svg/woman-people.svg"
              alt=""
              width="300"
              class="mb-2 d-block mx-auto"
            />
            <strong>Não há premissas cadastradas</strong>
          </div>
          <b-card v-if="inRegister" class="mb-4" border-variant="primary">
            <strong class="text-primary d-block mb-3">
              Adicionar premissa
            </strong>
            <div class="w-100 d-block">
              <b-form-group
                label="Descrição"
                description="Digite brevenemente algo que acha importante validarmos. Ex.: Validar conhecimento em manutenção preventiva."
                class="mb-3 mb-md-0 w-100"
              >
                <b-form-textarea
                  class="mt-1"
                  autofocus
                  v-model="newpremise.description"
                  label="title"
                  placeholder="Selecione o tipo do campo"
                >
                </b-form-textarea>
              </b-form-group>
            </div>
            <div class="d-flex align-items-center justify-content-end mt-3">
              <b-button
                variant="light"
                class="mr-2"
                size="sm"
                @click="handleCancelRegister()"
              >
                Cancelar
              </b-button>
              <b-button
                variant="primary"
                size="sm"
                @click="handleUpdatepremise(newpremise)"
                :disabled="!(newpremise.description.length > 3)"
              >
                Salvar
              </b-button>
            </div>
          </b-card>

          <b-list-group
            v-for="(premise, index) in premises"
            :key="index"
            v-disable="inRegister"
            class="w-100 mb-2"
          >
            <b-list-group-item
              style="user-select: none"
              href="#"
              @click="handleOpenDrawer(premise)"
            >
              <div
                class="
                  d-block d-md-flex
                  align-items-center
                  justify-content-between
                  flex-md-row
                "
              >
                <section class="mr-4">
                  <span
                    class="mt-1 text-break"
                    :show-labels="false"
                    placeholder="Selecione o tipo do campo"
                  >
                    {{ premise.description }}
                  </span>
                </section>
                <section class="col-md-2 p-0">
                  <span>
                    <b-icon
                      :icon="variantStatus(premise.status).icon"
                      :variant="variantStatus(premise.status).variant"
                      class="mr-2"
                    />
                    <span>{{ variantStatus(premise.status).label }} </span>
                  </span>
                  <div>
                    <span class="mr-2">{{ premise.comment_cnt || 0 }}</span>
                    <b-icon icon="chat" />
                  </div>
                </section>
              </div>
              <section v-if="premise.commentns && premise.commentns.length">
                <b-alert variant="info" show class="mt-3 mb-0">
                  Clique para entrar na conversa...
                </b-alert>
              </section>
            </b-list-group-item>
          </b-list-group>
        </div>
      </div>

      <b-modal
        id="archive-premise"
        size="sm"
        hide-footer
        hide-header
        body-class="py-4"
        centered
        no-close-on-backdrop
        no-close-on-esc
        title="Atenção"
      >
        <div>
          <blockquote>
            Você está prestes a arquivar a solicitação
            <strong>{{ selectedpremise.description }}</strong>
            <span class="d-block text-danger">
              Essa ação não poderá ser desfeita
            </span>
          </blockquote>
          <div class="text-center">
            <b-button
              block
              variant="primary"
              class="p-0"
              @click="handleCloseModal()"
            >
              Desistir de arquivar a solicitação
            </b-button>
            <span class="text-center"> ou</span>
            <b-button
              block
              size="sm"
              variant="link"
              @click="handleArchivePremise()"
            >
              Arquivar a solicitação
            </b-button>
          </div>
        </div>
      </b-modal>
      <b-sidebar
        id="sidebarpremise"
        backdrop-variant="light"
        backdrop
        right
        shadow
        align="bottom"
        width="400px"
      >
        <template #default="{ hide }">
          <drawer-content
            :premise="selectedpremise"
            :hide="hide"
            ref="drawerComments"
          />
        </template>
      </b-sidebar>
    </div>
    <div class="bottom-navigation-service">
      <b-button
        variant="light"
        :to="
          `/homologation/${homologation_id}/service/${service_id}/edit/details`
        "
      >
        <span>Voltar</span>
      </b-button>
      <div class="d-flex align-items-center">
        <b-button
          variant="outline-primary"
          class="mr-4"
          :disabled="loadingSubmit"
          @click="goToNext(false)"
        >
          <b-spinner v-if="loadingSubmit" small class="mr-2" />
          <span>Concluir edição</span>
        </b-button>
        <b-button
          variant="primary"
          :disabled="loadingSubmit"
          @click="goToNext(true)"
        >
          <b-spinner v-if="loadingSubmit" small class="mr-2" />
          <span>Avançar para convites</span>
        </b-button>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import DrawerContent from "./DrawerContent.vue";

export default {
  components: {
    DrawerContent,
  },
  data() {
    return {
      selectedId: null,
      service_id: null,
      homologation_id: null,
      emptypremises: false,
      newpremise: {
        description: "",
      },
      selectedpremise: "",
      loadingSubmit: false,
      inRegister: false,
      loading: false,
      premises: [],
    };
  },
  computed: {
    ...mapGetters(["user"]),
  },
  methods: {
    ...mapActions([
      "add_premise",
      "get_all_premises",
      "edit_premises",
      "delete_premise",
    ]),
    handleArchivePremise() {
      this.editPemisse({
        ...this.selectedpremise,
        status: 4,
      });
    },
    handleCloseModal() {
      this.$bvModal.hide("archive-premise");
    },
    handleOpenDrawer(premise) {
      this.selectedpremise = premise;
      this.$root.$emit("bv::toggle::collapse", "sidebarpremise");
      this.$refs.drawerComments.getAllComments(premise);
    },
    handleAddPremise() {
      this.inRegister = true;
    },

    handleCancelRegister() {
      this.inRegister = false;
      this.newpremise = {
        description: "",
        type: "",
      };
    },
    addPremise(form) {
      this.add_premise({ ...form }).then(() => {
        this.loadingSubmit = false;
        this.handleGetpremises();
        this.handleCancelRegister();
      });
    },
    editPemisse(form) {
      this.edit_premises({ ...form }).then(() => {
        this.handleGetpremises();
        this.handleCancelRegister();
        this.handleCloseModal();
        this.loadingSubmit = false;
        this.$root.$emit("bv::toggle::collapse", "sidebarpremise");
      });
    },
    goToNext(next) {
      if (!next) {
        this.$router.push(`/homologation/${this.homologation_id}/service/list`);
        return;
      }
      this.$router.push(
        `/homologation/${this.homologation_id}/service/${
          this.service_id ? `${this.service_id}/edit/invites/` : ""
        }`
      );
    },
    handleUpdatepremise({ id, description }) {
      if (!description) {
        return;
      }
      this.loadingSubmit = true;
      const length = this.premises.length;
      const previous = length === 0 ? null : this.premises[length - 1].id;
      const data = {
        service: this.service_id,
        description,
        previous,
      };

      if (id) {
        this.editPemisse({
          service: this.service_id,
          description,
          id,
          previous: null,
        });
      } else {
        this.addPremise(data);
      }
    },
    handleGetpremises() {
      this.loading = true;
      this.get_all_premises({ service_id: this.service_id }).then(
        ({ data: { results } }) => {
          this.loading = false;
          this.emptypremises = results?.length === 0;
          this.premises = results;
        }
      );
    },
    handleDeletepremise(id) {
      this.selectedId = id;
      this.delete_premise(id).then(() => {
        this.handleGetpremises();
      });
    },
    variantStatus(status) {
      let icon;
      let variant;
      let label;
      switch (status) {
        case 1:
          icon = "clock-fill";
          variant = "warning";
          label = "Aguardando validação";
          break;
        case 2:
          icon = "x-circle-fill";
          variant = "danger";
          label = "Premissa recusada";
          break;
        case 3:
          icon = "check-circle-fill";
          variant = "success";
          label = "Premissa aceita";
          break;
        case 4:
          icon = "dash-circle-fill";
          variant = "default";
          label = "Arquivada";
          break;
      }
      return {
        icon,
        variant,
        label,
      };
    },
  },
  mounted() {
    this.service_id = this.$route.params.service_id;
    this.homologation_id = this.$route.params.homologation_id;
    this.handleGetpremises();
  },
};
</script>
